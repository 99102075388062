<template>
  <v-card>
    <v-card-title>
      <div
        class="d-flex flex-column"
        style="gap: 15px;"
      >
        <span>
          Vendas não alocadas
        </span>

        <v-row class="d-flex justify-content-center">
          <v-col
            class="d-flex justify-content-center"
          >
            <v-btn
              color="info"
              outlined
              @click="openModalFilter()"
            >
              Filtro
            </v-btn>

            <v-btn
              color="secondary"
              outlined
              class="ml-2"
              @click="resetFilter()"
            >
              Limpar
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-spacer />

      <v-btn
        color="info"
        @click="allocateSale()"
      >
        <v-icon
          size="18"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>

        <span>
          Alocar vendas
        </span>
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsList"
      :loading="loading"
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      loading-text="Carregando dados..."
      hide-default-footer
      disable-sort
      @page-count="pageCount = $event"
    >
      <template #[`item.bonus_value`]="{item}">
        {{ Number(item.bonus_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template #[`item.actions`]="{item}">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="allocateSaleModal(item.id)"
            >
              <v-icon
                v-if="item.id === loadingSpinner ? false : true"
                size="22"
              >
                {{ icons.mdiTableCog }}
              </v-icon>

              <v-progress-circular
                v-if="item.id === loadingSpinner ? true : false"
                indeterminate
                :size="25"
                color="info"
              />
            </v-btn>
          </template>

          <span>Alocar venda</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-center"
        >
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="openModal"
      width="600"
    >
      <modal-sales-unallocated
        :sales-id="salesIdUnallocated"
        :updated-table="updatedTable"
        @close="openModal = false"
      />
    </v-dialog>

    <v-dialog
      v-model="opeModalFilter"
      width="800"
    >
      <v-card>
        <v-card-text>
          <v-card
            class="pt-4 pr-4 pl-4"
            outlined
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="filterOptions.client_name"
                  label="Nome do Cliente"
                  clearable
                  outlined
                  dense
                />

                <v-text-field
                  v-model="filterOptions.vehicle_model"
                  label="Modelo do Veículo"
                  clearable
                  outlined
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="filterOptions.cpf_cnpj"
                  label="CPF/CNPJ"
                  clearable
                  outlined
                  dense
                />

                <v-text-field
                  v-model="filterOptions.invoice_number"
                  label="Número Nota Fiscal"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <div
              class="d-flex"
              style="gap: 25px;"
            >
              <v-text-field
                v-model="filterOptions.contract_number"
                label="Número do Contrato"
                clearable
                outlined
                dense
              />

              <v-menu
                v-model="dateMenuInitial"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterOptions.initial_reference"
                    label="Referência Inicial"
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filterOptions.initial_reference"
                  locale="pt-BR"
                  width="270"
                  @input="dateMenuInitial = false;"
                />
              </v-menu>

              <v-menu
                v-model="dateMenuFinal"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterOptions.final_reference"
                    outlined
                    dense
                    clearable
                    label="Referência Final"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filterOptions.final_reference"
                  locale="pt-BR"
                  width="270"
                  @input="dateMenuFinal = false;"
                />
              </v-menu>
            </div>

            <div
              class="d-flex align-center"
              style="margin-top: -10px; gap: 15px;"
            >
              <span class="text-subtitle-1">
                Não alocadas:
              </span>

              <v-switch
                v-model="filterOptions.unallocated"
                color="success"
                dense
                inset
              />
            </div>
          </v-card>
        </v-card-text>

        <v-card-actions
          class="d-flex justify-end"
          style="gap: 10px;"
        >
          <v-btn
            outlined
            color="error"
            @click="clearModalFilter"
          >
            Cancelar
          </v-btn>

          <v-btn
            outlined
            color="success"
            @click="filterSalesAllocated()"
          >
            Filtrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCloseCircleOutline, mdiMagnify, mdiPlus, mdiTableCog,
} from '@mdi/js'
import modalSalesUnallocated from './ModalSalesUnallocated.vue'

export default {
  components: {
    modalSalesUnallocated,
  },
  data() {
    return {
      mode: 'general',

      dateMenuInitial: false,
      dateMenuFinal: false,

      filterOptions: {
        client_name: '',
        cpf_cnpj: '',
        vehicle_model: '',
        invoice_number: '',
        contract_number: '',
        initial_reference: '',
        final_reference: '',
        unallocated: true,
      },

      opeModalFilter: false,
      clientName: '',

      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },

      salesIdUnallocated: '',
      openModal: false,
      loadingSpinner: '',

      icons: {
        mdiTableCog,
        mdiPlus,
        mdiMagnify,
        mdiCloseCircleOutline,
      },

      loading: false,
      itemsList: [],

      headers: [
        {
          text: 'NOME VENDEDOR', value: 'user.name', sortable: false, align: 'center',
        },
        {
          text: 'VALOR DO BÔNUS', value: 'bonus_value', sortable: false, align: 'center',
        },
        {
          text: 'NOME DO CLIENTE', value: 'client_name', sortable: false, align: 'center',
        },
        {
          text: 'CPF CLIENTE', value: 'cpf_cnpj', sortable: false, align: 'center',
        },
        {
          text: 'NOTA FISCAL', value: 'invoice_number', sortable: false, align: 'center',
        },
        {
          text: 'REFERÊNCIA', value: 'reference', sortable: false, align: 'center',
        },
        {
          text: 'CONTRATO', value: 'contract_number', sortable: false, align: 'center',
        },
        {
          text: 'AÇÕES', value: 'actions', sortable: false, align: 'center',
        },
      ],

      saleId: '',
    }
  },

  created() {
    this.getItemsList()
  },

  methods: {
    async getItemsList() {
      this.loading = true

      await axiosIns.get('/api/v1/integration/delivery/not_identified')
        .then(res => {
          this.itemsList = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
          this.mode = 'general'
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async updatedTable() {
      try {
        this.loading = true

        await this.getItemsList()
        await this.updatePage()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async updatePage() {
      this.loading = true
      this.itemsList = []

      if (this.mode === 'general') {
        await axiosIns.get(`/api/v1/integration/delivery/not_identified?page=${this.pageOptions.page}`)
          .then(res => {
            this.itemsList = res.data.data
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
      } else {
        await axiosIns.post(`/api/v1/integration/delivery/filter?page=${this.pageOptions.page}`, this.filterOptions)
          .then(res => {
            this.itemsList = res.data.data
            this.pageOptions.itemsPerPage = res.data.per_page
            this.pageOptions.pageCount = res.data.last_page
            this.loading = false
          })
      }
    },

    async allocateSale() {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja alocar as vendas listadas abaixo?',
        text: 'A operação é irreversível, tem certeza?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, alocar!',
        cancelButtonText: 'Não, cancelar!',
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true
          axiosIns.post('/api/v1/integration/delivery/integra_cloud_delivery')
            .then(res => {
              this.$swal({
                icon: 'success',
                title: 'Sucesso!',
                text: `${res.data[0][0].Integrado} !`,
                showConfirmButton: false,
                timer: 4500,
              })
              this.updatedTable()
              this.loading = true
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Erro ao alocar venda!',
                showConfirmButton: false,
                text: error,
                timer: 2500,
              })
              this.loading = false
            })
        }
      })
    },

    allocateSaleModal(id) {
      this.loadingSpinner = id

      this.salesIdUnallocated = id
      this.openModal = true

      this.loadingSpinner = ''
      this.saleId = id
    },

    openModalFilter() {
      this.opeModalFilter = true
    },

    filterSalesAllocated() {
      this.opeModalFilter = false
      this.loading = true

      axiosIns.post('/api/v1/integration/delivery/filter', this.filterOptions)
        .then(res => {
          this.itemsList = res.data.data
          this.pageOptions.itemsPerPage = res.data.per_page
          this.pageOptions.pageCount = res.data.last_page
          this.loading = false
          this.mode = 'filter'
        })
        .catch(error => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${error}`
        })
    },

    clearModalFilter() {
      this.opeModalFilter = false

      this.filterOptions = {
        client_name: '',
        cpf_cnpj: '',
        vehicle_model: '',
        invoice_number: '',
        contract_number: '',
        initial_reference: '',
        final_reference: '',
        unallocated: true,
      }
    },

    resetFilter() {
      this.getItemsList()

      this.filterOptions = {
        client_name: '',
        cpf_cnpj: '',
        vehicle_model: '',
        invoice_number: '',
        contract_number: '',
        initial_reference: '',
        final_reference: '',
        unallocated: true,
      }
    },
  },
}
</script>
