<template>
  <v-card>
    <v-card-title>
      Alocar venda
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="vendorId"
            :items="itemsVendors"
            :loading="loadingVendorList"
            :disabled="!itemsVendors.length"
            outlined
            dense
            label="Nome vendedor"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <div>
        <v-checkbox
          v-model="noPerteneceValue"
          label="Não pertence a concessionária"
        />
      </div>

      <v-spacer></v-spacer>
      <v-row class="d-flex justify-content-center">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!noPerteneceValue && !vendorId"
          text
          @click="vendorId ? allocatedSale() : noPertenece()"
        >
          Adicionar
        </v-btn>
        <v-btn
          color="error"
          text
          @click="$emit('close'), clearModal()"
        >
          Cancelar
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  props: {
    salesId: {
      type: String,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      itemsVendors: '',
      noPerteneceValue: '',
      vendorId: '',
      companyVendorId: '',
      loadingVendorList: false,
    }
  },
  watch: {
    vendorId(n) {
      this.loadingVendorList = true
      axiosIns.get(`api/v1/records/user/edit/${n}`)
        .then(res => {
          this.companyVendorId = res.data.data.company.id
          this.loadingVendorList = false
        })
    },
  },

  created() {
    this.loadingVendorList = true
    this.getListVendors()
  },
  methods: {
    async getListVendors() {
      await axiosIns('api/v1/records/user/index')
        .then(res => {
          this.itemsVendors = res.data.data
          this.loadingVendorList = false
        })
    },

    async noPertenece() {
      const body = {
        id: this.salesId,
        does_not_belong: this.noPerteneceValue,
      }
      await axiosIns.post('/api/v1/integration/delivery/does_not_belong_company', body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados Não pertencem a concessionária!',
            showConfirmButton: false,
            timer: 2500,
          })
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao alocar venda!',
            showConfirmButton: false,
            text: error,
            timer: 2500,
          })
        })
      this.$emit('close')
      this.clearModal()
      this.updatedTable()
    },

    async allocatedSale() {
      const body = {
        users_id: this.vendorId,
        companies_id: this.companyVendorId,
      }

      await this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Você tem certeza que deseja alocar esta venda para o vendedor indicado?🤔',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, alocar!',
        cancelButtonText: 'Não, cancelar!',
      }).then(result => {
        if (result.isConfirmed) {
          axiosIns.put(`api/v1/integration/delivery/update/${this.salesId}`, body)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Dados da alocados com sucesso!',
                showConfirmButton: false,
                timer: 2500,
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Erro ao alocar venda!',
                showConfirmButton: false,
                text: error.response.status === 405 ? error.response.data.data.mensage : error,
                timer: 3000,
              })
            })
        }

        this.$emit('close')
        this.clearModal()
        this.updatedTable()
      })
    },
    clearModal() {
      this.vendorId = ''
      this.noPerteneceValue = ''
    },
  },
}
</script>
