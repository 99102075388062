import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Alocar venda ")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"items":_vm.itemsVendors,"loading":_vm.loadingVendorList,"disabled":!_vm.itemsVendors.length,"outlined":"","dense":"","label":"Nome vendedor","item-text":"name","item-value":"id"},model:{value:(_vm.vendorId),callback:function ($$v) {_vm.vendorId=$$v},expression:"vendorId"}})],1)],1),_c('div',[_c(VCheckbox,{attrs:{"label":"Não pertence a concessionária"},model:{value:(_vm.noPerteneceValue),callback:function ($$v) {_vm.noPerteneceValue=$$v},expression:"noPerteneceValue"}})],1),_c(VSpacer),_c(VRow,{staticClass:"d-flex justify-content-center"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","disabled":!_vm.noPerteneceValue && !_vm.vendorId,"text":""},on:{"click":function($event){_vm.vendorId ? _vm.allocatedSale() : _vm.noPertenece()}}},[_vm._v(" Adicionar ")]),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.$emit('close'), _vm.clearModal()}}},[_vm._v(" Cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }